<template>
  <div class="integration-page">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!isLoadingIntegration && integration">
              <ValidationObserver
                v-slot="{ handleSubmit, invalid }"
                ref="createForm"
              >
                <form
                  class="authentication-form"
                  @submit.prevent="handleSubmit(updateIntegration)"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <div class="integration-item">
                        <img :src="integration.icon" :alt="integration.title" class="integration-icon">
                        <div>
                          <h3>{{ integration.title }}</h3>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mt-4">
                      <TextInput
                        v-model="data.name"
                        name="IntegrationName"
                        label="Integration Name"
                        sublabel="(*)"
                        rules="required"
                      />
                    </div>
                  </div>
                  <Samcart v-if="integration.key === 'samcart'" :obj="data" :is-edit="true" @syncData="clickSyncSamcartData" />
                  <Sendgrid v-if="integration.key === 'sendgrid'" :obj="data" :is-edit="true" />
                  <Goto v-if="integration.key === 'goto'" :obj="data" :is-edit="true" />
                  <Stripe v-if="integration.key === 'stripe'" :obj="data" :is-edit="true" />
                  <Ses v-if="integration.key === 'ses'" :obj="data" :is-edit="true" />
                  <Facebook v-if="integration.key === 'fb'" :obj="data" :is-edit="true" />

                  <div class="d-flex mt-4">
                    <router-link
                      :to="{ name: 'business.settings.integrations', query: { tab: 1 } }"
                      class="btn btn-warning mr-4"
                    ><i class="uil uil-arrow-left"></i> Back
                    </router-link>
                    <b-button
                      variant="primary"
                      type="submit"
                      :disabled="processing || invalid"
                    >
                      <b-spinner v-if="processing" small />
                      <span v-else>{{ updateButtonLabel }}</span>
                    </b-button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showConfirmModal" title="Sync Samcart Data">
      <p>Are you sure you want to sync Samcart data</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showConfirmModal = false">Cancel</b-button>
        <b-button variant="primary" @click="onSynceData">Process</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import Samcart from './Components/Samcart.vue'
import Sendgrid from './Components/Sendgrid.vue'
import Goto from './Components/Goto.vue'
import Facebook from './Components/Facebook.vue'
import Stripe from './Components/Stripe.vue'
import Ses from './Components/Ses.vue'
import { makeString } from '@src/helper'
import uniqid from 'uniqid'

export default {
  components: { 
    Samcart, Sendgrid, Goto, Stripe, Ses, Facebook
  },

  data() {
    return {
      isLoadingIntegration: false,
      integration: null,
      processing: false,
      showConfirmModal: false,
      data: {},
    }
  },

  computed: {
    updateButtonLabel() {
      let res = 'Update'
      if (this.integration)
      {
        switch (this.integration.key) {
          case 'goto':
          case 'fb':
            res = 'Refresh Connection'
            break;
          case 'stripe':
            res = 'Sync Data'
            break;
          default:
            break;
        }
      }
      return res
    },

    syncProcessing() {
      return this.processing || !!this.integration.sync_process
    },

    enabledSyncData() {
      return true
    },
  },

  mounted() {
    if (window.addEventListener) {
      window.addEventListener("message", this.postMessageHandler)
    } else {
      window.attachEvent("onmessage", this.postMessageHandler);
    }

    this.getIntegration()
  },

  beforeDestroy() {
    if (window.addEventListener) {
      window.removeEventListener('message', this.postMessageHandler);
    } else {
      window.removeEvent('onmessage', this.postMessageHandler);
    }
  },

  methods: {
    postMessageHandler(event)
    {
      let eventData = event.data
      if (eventData.a) // check if valid request
      {
        if (this.authWin)
        {
          this.authWin.close();
        }
        let err = eventData.c
        let code = this.decrtyp(eventData.b)
        if (code)
        {
          this.data.code = code
          this.updateIntegration()
        } else {
          let errMsg = 'Something went wrong, Please try again later'
          if (err)
          {
            errMsg = eventData.d
          }
          
          Vue.$toast.open({
            message: errMsg,
            type: 'error',
            duration: 5000,
          })

          this.processing = false
        }
      }
    },

    initIntegration(integration) {
      this.integration = integration
      this.data = {
        ...integration,
        editApiKey: false,
        api_key_new: '',
        key_new: '',
        from_email_new: '',
      }
    },
    getIntegration() {
      this.isLoadingIntegration = true

      this.$store
        .dispatch('integration/find', this.$route.params.id)
        .then((integration) => {
          this.initIntegration(integration)
          this.isLoadingIntegration = false
        })
        .catch(() => {
          this.isLoadingIntegration = false
          this.$router.push({ name: 'business.settings.integrations' })
        })
    },

    clickSyncSamcartData() {
      this.showConfirmModal = true
    },

    onSynceData() {
      this.showConfirmModal = false
      this.processing = true

      this.$store
        .dispatch('integration/syncData', this.integration.id)
        .then((integration) => {
          this.initIntegration(integration)
          this.processing = false
        })
        .catch(() => {
          this.getIntegration()
          this.processing = false
        })
    },

    handleReconnect() {
      if (this.integration.auth_type === 'oauth') {
        let oauthUrl = this.integration.oauth_url
        let redirectUrl = this.data.redirect_url
        oauthUrl = oauthUrl + `?client_id=${this.integration.client_id}&response_type=code&redirect_uri=${encodeURIComponent(redirectUrl)}`

        let fullUrl = oauthUrl
        let winTitle = 'OAuth Connect'
        if (this.integration.key === 'fb') {
          winTitle = 'Facebook - Connect'
          let state = `${makeString(30)}${uniqid()}`
          let scope = 'pages_show_list,leads_retrieval,pages_manage_ads,pages_manage_metadata,pages_read_engagement,business_management'
          this.data.options['state'] = state
          this.data.options['scope'] = scope

          fullUrl = fullUrl + `&scope=${scope}&state=${state}`
        }

        let w = 800
        let h = 800
        let left = (screen.width / 2) - (w / 2)
        let top = (screen.height / 2) - (h / 2)

        this.authWin = window.open(fullUrl, winTitle, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
      }
    },

    updateIntegration() {
      if (!this.data.code && ['fb'].includes(this.integration.key)) {
        this.handleReconnect()
        return
      }
      this.processing = true

      const param = {
        ...this.integration,
        ...this.data,
      }

      delete param.api_key

      if (this.data.editApiKey)
      {
        param.api_key = this.data.api_key_new
        param.options.key = this.data.key_new
        param.options.from_email = this.data.from_email_new
      }

      this.$store
        .dispatch('integration/update', param)
        .then((integration) => {
          if (this.editApiKey)
            this.cancelEditApiKey()
          this.initIntegration(integration)
          this.processing = false
        })
        .catch(() => {
          this.processing = false
        })
    },

    handleDisconnectIntegration() {
      this.processing = true

      this.$store
        .dispatch('integration/disconnect', this.integration.id)
        .then((integration) => {
          this.integration = integration
          this.processing = false
        })
        .catch(() => {
          this.processing = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.box-link {
  display: flex;
  justify-content: center;
}
.integration-item {
  border-radius: 5px;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  h3 {
    flex: 1 1 auto;
    font-size: 18px;
  }
  .integration-icon {
    margin-right: 10px;
    max-width: 100px;
  }
}
</style>

<style lang="scss">
.integration-page {
  .copy-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      width: 16px;
      color: #5369f8;
    }
  }
}
</style>